import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createConsumer } from '../../context';
import Input from '../../components/Input';
import Button from '../../components/Button';

import styles from './Login.scss';

class Login extends Component {
  state = {
    disabled: false,
  };

  usernameRef = React.createRef();

  passwordRef = React.createRef();

  componentDidMount() {
    this.handleOnChange();
  }

  handleOnChange = () => {
    const username = this.usernameRef.current.ref.current.value;
    const password = this.passwordRef.current.ref.current.value;

    if (
      username &&
      username !== '' &&
      username.length > 3 &&
      password &&
      password !== '' &&
      password.length > 3
    ) {
      this.setState({
        error: null,
        disabled: false,
      });
    } else {
      this.setState({
        error: null,
        disabled: true,
      });
    }
  }

  handleLogin = async () => {
    const { logIn, history } = this.props;
    this.setState({ error: null });
    const username = this.usernameRef.current.ref.current.value;
    const password = this.passwordRef.current.ref.current.value;

    if (username && username !== '' && password && password !== '') {

      const error = await logIn({
        username,
        password
      });

      if (error) {
        this.setState({ error });
        return null;
      }

      history.push('/');
    }
  }

  render() {
    const { error, disabled } = this.state;

    return (
      <div className={styles.loginWrapper}>
        <img src="/images/logo.png" height="40" alt="" />
        <h5>Вход</h5>
        <div className="box">
          <div className={styles.loginHolder}>
            <div className={styles.login}>
              <div className="form-group">
                <Input
                  type="text"
                  name="username"
                  label="Е-мейл адрес"
                  width={240}
                  placeholder="Вашият Е-мейл"
                  ref={this.usernameRef}
                  onChange={this.handleOnChange}
                />
              </div>

              <div className={styles.formGroup}>
                <Input
                  type="password"
                  name="password"
                  label="Парола"
                  width={240}
                  placeholder="вашата парола"
                  ref={this.passwordRef}
                  onChange={this.handleOnChange}
                />
              </div>

              {
                error && <div className="invalid">{error.message}</div>
              }

              <Button
                color="blue"
                width={240}
                disabled={disabled}
                value='Вход'
                onClick={this.handleLogin}
              />
            </div>

            <div className={styles.footer}>
              <p>Все още нямаш акаунт? <Link to="/register">Създай Сега</Link></p>
              <p><Link to="/forgot">Забравена парола</Link></p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapContextToProps = ({ action }) => ({
  logIn: action.logIn,
});

export default createConsumer(mapContextToProps)(Login);
