import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { createConsumer } from '../../context';

import Input from '../../components/Input';
import Button from '../../components/Button';

import styles from './Register.scss';

class Register extends Component {
  state = {
    username: '',
    password: '',
    passwordConfirm: '',
    disabled: true,
  };

  handleOnChange = ({ target }) => {
    this.setState({
      [target.name]: (target.value).toLowerCase(),
      error: null,
    }, () => {
      const { username, password, passwordConfirm } = this.state;

      if (
        username !== '' &&
        password !== '' &&
        passwordConfirm !== '' &&
        password === passwordConfirm
      ) {
        this.setState({ disabled: false });
      } else {
        this.setState({ disabled: true });
      }
    });


  }

  handleRegister = async () => {
    const { register, history } = this.props;
    this.setState({ error: null })
    const { username, password } = this.state;

    const errorRegister = await register({
      username,
      password
    });

    if (errorRegister) {
      this.setState({ error: errorRegister });
      return null;
    }

    history.push('/');
  }

  render() {
    const { error } = this.state;

    return (
      <div className={styles.loginWrapper}>
        <img src="/images/logo.png" height="40" alt="" />
        <h5>Регистрация</h5>
        <div className="box">
          <div className={styles.loginHolder}>
            <div className={styles.login}>
              <div className="form-group">
                <Input
                  type="text"
                  name="username"
                  label="Е-мейл адрес"
                  width={240}
                  placeholder="Вашият Е-мейл"
                  onChange={this.handleOnChange}
                />
              </div>

              <div className={styles.formGroup}>
                <Input
                  type="password"
                  name="password"
                  label="Парола"
                  width={240}
                  placeholder="вашата парола"
                  onChange={this.handleOnChange}
                />

                <Input
                  type="password"
                  name="passwordConfirm"
                  label="Повтори паролата"
                  width={240}
                  placeholder="повтори паролата"
                  onChange={this.handleOnChange}
                />
              </div>

              {
                error && <p className="invalid">{error.message}</p>
              }

              <Button
                color="blue"
                width={240}
                disabled={this.state.disabled}
                value='Регистрация'
                onClick={this.handleRegister}
              />
            </div>

            <div className={styles.footer}>
              <p>Имаш акаунт? <Link to="/login">Влез</Link></p>
              <p><Link to="/forgot">Забравена парола</Link></p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapContextToProps = ({ state, action }) => ({
  register: action.register,
});

export default createConsumer(mapContextToProps)(Register);
