import React, { Fragment, Component } from 'react';
import classnames from 'classnames';

import styles from './Input.scss';

class Input extends Component {
  constructor(props) {
    super(props);

    const { initialData } = props;

    this.state = {}
  }

  ref = React.createRef();

  customFocus = () => {
    this.ref.current.focus();
  }

  render() {
    const {
      className,
      wrapperProps,
      width,
      name,
      label,
      sufix,
      error,
      multiline,
      ...rest
    } = this.props;

    return (
      <div {...wrapperProps} className={classnames(styles.inputHolder, wrapperProps.className)} style={width ? { width: `${width}px` } : {}}>
        <label htmlFor={name} className={styles.inputLabel}>
          {
            label && <span>{label}</span>
          }
        </label>
        <div className={classnames(styles.inputWrapper, { [styles.hasSufix]: sufix, [styles.focused]: this.state.focused })}>
          {
            multiline
              ? (
                <textarea
                  className={classnames(className, styles.input)}
                  id={name}
                  name={name}
                  type="text"
                  ref={this.ref}
                  onBlur={() => {
                    this.setState({ focused: false })
                  }}
                  onFocus={() => {
                    this.setState({ focused: true })
                  }}
                  {...rest}
                />
              )
              : (
                <Fragment>
                  <input
                    className={classnames(className, styles.input)}
                    id={name}
                    name={name}
                    type="text"
                    ref={this.ref}
                    onBlur={() => {
                      this.setState({ focused: false })
                    }}
                    onFocus={() => {
                      this.setState({ focused: true })
                    }}
                    {...rest}
                  />
                  {
                    sufix && <div className={styles.sufix}>{sufix}</div>
                  }
                </Fragment>
              )
          }
        </div>
        {error && <span>{error}</span>}
      </ div>
    );
  }
}

Input.defaultProps = {
  wrapperProps: {},
  label: null,
  name: null,
  className: null,
}

export default Input;
